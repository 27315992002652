import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"900px","content-class":"custom-dialog"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VBtn,{staticStyle:{"position":"absolute","top":"20px","right":"20px"},attrs:{"icon":"","color":"#707070"},on:{"click":_vm.closeModal}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VCardText,{staticClass:"py-4"},[_c('h4',[_vm._v("Beneficios de Convertirte en Vendedor")]),_c(VContainer,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"text-center justify-center",staticStyle:{"color":"#0064F1"},attrs:{"cols":"12","md":"9"}},[_c(VExpansionPanels,{attrs:{"value":0}},_vm._l((_vm.texts),function(item,i){return _c(VExpansionPanel,{key:i},[_c(VExpansionPanelHeader,{attrs:{"color":"#0064F1"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}],null,true)},[_c('h5',{staticClass:"body-1 font-weight-bold white--text my-0"},[_vm._v(_vm._s(item.title))])]),_c(VExpansionPanelContent,{staticClass:"pa-3 text-justify",domProps:{"innerHTML":_vm._s(item.text)}})],1)}),1)],1),_c(VCol,{staticClass:"text-center justify-center",staticStyle:{"color":"#707070"},attrs:{"cols":"12","md":"3"}},[_c(VImg,{staticClass:"logo mx-auto mb-0",attrs:{"src":require('@/assets/images/logo-top.svg'),"width":"200","max-height":"300","contain":""}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }