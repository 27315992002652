<template>
  <v-container fluid style="background-color: #0064F1">
		<v-container>
			<v-row
				align="center"
				no-gutters
			>
				<v-col>
					<h3 class="white--text mt-0">¡Sé vendedor ahora!</h3>
					<p class="white--text">Inscríbete ahora y empieza a vender productos Iduam, te apoyaremos en todo el proceso</p>
				</v-col>
				<v-col md="4">
					<v-btn
						depressed
						color="white"
						block
						href="#contact"
					>
						<v-icon class="mr-3">
							mdi-card-account-mail-outline
						</v-icon>
						Conviértete en Vendedor
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
  </v-container>
</template>

<script>

export default {
  name: "Action",
  components: {
  },
  data() {
    return {
    };
  },
  methods: {},
  mounted() {
  },
};
</script>

<style>
</style>