<template>
	<v-dialog
			v-model="dialog"
			persistent
			max-width="600px"
			content-class="custom-dialog"
		>
		<v-card>
			<v-btn
				icon
				color="#707070"
				@click="closeModal"
				style="position: absolute; top: 20px; right: 20px;"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12" style="color: #707070" class="text-center justify-center">
							<v-img
								:src="require('@/assets/images/success.svg')"
								width="250"
								max-height="300"
								contain
								class="mx-auto mb-6"
							></v-img>
							<h3 class="headline">¡Genial, hemos recibido tus datos correctamente!</h3>
							<p class="mb-0">Gracias por registrarte como vendedor.</p>
							<p class="font-weight-bold my-4">Te recordamos que para concluir el proceso de registrarte como vendedor tendrás que comprar el Kit de IDUAM con un descuento especial para ti de $80.000 sobre el precio de venta, por lo que solo tendrás que pagar $69.990</p>
							<v-divider></v-divider>
							<p class="font-weight-bold mt-4 mb-0">Recibirás un email en el correo electrónico que ingresaste.</p>
							<p class="caption mb-0">Por favor revisa tu email en unos segundos, verifica también tu carpeta spam.</p>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	
	export default {
		name: 'ModalConfirmation',
	props: {
		dialog: {
			type: Boolean,
			default: false
		},
		closeModal: {
			type: Function,
			required: true
		}
	},
		data: () => ({
		}),
		mounted () {
		},
		methods: {
		}
	}
</script>