<template>
  <v-container id="info">
		<v-row
			align="center"
			no-gutters
			class="my-5"
		>
			<v-col>
				<div class="d-flex flex-column justify-center align-center flex-1 py-4 px-5">
					<!-- <v-icon
						x-large
						color="#0064F1"
					>
						mdi-cash-multiple
					</v-icon> -->
					<v-img
						max-height="150"
						max-width="150"
						:src="require('@/assets/images/money.png')"
						class="mb-5"
					></v-img>
					<p class="mt-4 text-center">Obtén ganancias por promover y vender nuestros productos</p>
				</div>
			</v-col>
			<v-divider vertical></v-divider>
			<v-col>
				<div class="d-flex flex-column justify-center align-center flex-1 py-4 px-5">
					<v-img
						max-height="150"
						max-width="150"
						:src="require('@/assets/images/cupon.png')"
						class="mb-5"
					></v-img>
					<p class="mt-4 text-center">Adquiere una muestra de nuestros productos con un descuento de $80.000 en tu primera compra</p>
				</div>
			</v-col>
			<v-divider vertical></v-divider>
			<v-col>
				<div class="d-flex flex-column justify-center align-center flex-1 py-4 px-5">
					<v-img
						max-height="150"
						max-width="150"
						:src="require('@/assets/images/wallet.png')"
						class="mb-5"
					></v-img>
					<p class="mt-4 text-center">Gana comisión por todas las ventas que se cierren con tu código de referido</p>
				</div>
			</v-col>
		</v-row>
		<!-- <div class="d-flex flex-row justify-space-between">
			<div class="d-flex flex-column justify-center align-center flex-1">
				<v-icon
					x-large
					color="#0064F1"
				>
					mdi-cash-multiple
				</v-icon>
				<p class="mt-2 text-center">Obtén ganancias por promover y cerrar ventas de nuestros productos</p>
			</div>
			<v-divider vertical></v-divider>
		</div> -->
  </v-container>
</template>

<script>

export default {
  name: "Info",
  components: {
  },
  data() {
    return {
    };
  },
  methods: {},
  mounted() {
  },
};
</script>

<style>
</style>