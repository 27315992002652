import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home/index.vue'
import PreguntasFrecuentes from '../components/PreguntasFrecuentes/index.vue'
/* import Checkout from '@/components/checkout/index.vue' */
import Checkout2 from '@/components/checkout/Checkout2.vue'
import Sellers from '@/components/Sellers'
import Register from '@/components/Login/Register'
import Smartband from '@/components/Smartband/Smartband.vue'
import EstadoOrden from '@/components/checkout/EstadoOrden.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/preguntas_frecuentes',
    name: 'PreguntasFrecuentes',
    component: PreguntasFrecuentes
  },
/*   {
    path: '/Checkout',
    name: 'Checkout',
    component: Checkout
  }, */
  {
    path: '/Checkout',
    name: 'Checkout2',
    component: Checkout2
  },
  {
    path: '/sellers',
    name: 'Sellers',
    component: Sellers
  },
  {
    path: '/register-seller',
    name: 'Register',
    component: Register
  },
  {
    path: '/Smartband',
    name: 'Smartband',
    component: Smartband
  },
  {
    path: '/estado-orden',
    name: 'EstadoOrden',
    component: EstadoOrden
  }

]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
