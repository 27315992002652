<template>
  <v-container fluid class="pa-0">
		<div class="selector-container rounded-xl py-3 px-5 mb-2 d-flex flex-row justify-space-between"
			:class="typeShow === 1 ? 'selected white':''"
			@mouseover="typeShowChange(1)">
			<div>
				<h4 class="my-0">¿Qué es Iduam?</h4>
				<p class="mb-0">Es un asistente que monitorea diversos parámetros de salud ...</p>
			</div>

			<v-icon
				large
				color="grey lighten-1"
			>
				mdi-chevron-right
			</v-icon>
		</div>
		<div class="selector-container rounded-xl py-3 px-5 mb-2 d-flex flex-row justify-space-between"
			:class="typeShow === 2 ? 'selected white':''"
			@mouseover="typeShowChange(2)">
			<div>
				<h4 class="my-0">¿Qué le ofrecemos?</h4>
				<p class="mb-0">Unirse a un grupo enfocado en la venta de nuestro exclusivo producto Iduam ...</p>
			</div>

			<v-icon
				large
				color="grey lighten-1"
			>
				mdi-chevron-right
			</v-icon>
		</div>
		<div class="selector-container rounded-xl py-3 px-5 mb-2 d-flex flex-row justify-space-between"
			:class="typeShow === 3 ? 'selected white':''"
			@mouseover="typeShowChange(3)">
			<div>
				<h4 class="my-0">¿Cuáles son tus beneficios?</h4>
				<p class="mb-0">Descuento inicial de $80.000 y $30.000 por cada venta que realice ...</p>
			</div>

			<v-icon
				large
				color="grey lighten-1"
			>
				mdi-chevron-right
			</v-icon>
		</div>
		<div class="selector-container rounded-xl py-3 px-5 mb-2 d-flex flex-row justify-space-between"
			:class="typeShow === 4 ? 'selected white':''"
			@mouseover="typeShowChange(4)">
			<div>
				<h4 class="my-0">¿Cómo te apoyamos?</h4>
				<p class="mb-0">Descuentos a sus clientes y apoyo de nuestro equipo y de nuestros canales en Youtube ...</p>
			</div>

			<v-icon
				large
				color="grey lighten-1"
			>
				mdi-chevron-right
			</v-icon>
		</div>
		<div class="selector-container rounded-xl py-3 px-5 mb-2 d-flex flex-row justify-space-between"
			:class="typeShow === 5 ? 'selected white':''"
			@mouseover="typeShowChange(5)">
			<div>
				<h4 class="my-0">¿Qué es el Jackpot?</h4>
				<p class="mb-0">Un pozo acumulado repartido entre los 3 mejores vendedores ...</p>
			</div>

			<v-icon
				large
				color="grey lighten-1"
			>
				mdi-chevron-right
			</v-icon>
		</div>
		<div class="selector-container rounded-xl py-3 px-5 mb-2 d-flex flex-row justify-space-between"
			:class="typeShow === 6 ? 'selected white':''"
			@mouseover="typeShowChange(6)">
			<div>
				<h4 class="my-0">¿Cuáles son las condiciones?</h4>
				<p class="mb-0">Conoce todas las condiciones aquí ...</p>
			</div>

			<v-icon
				large
				color="grey lighten-1"
			>
				mdi-chevron-right
			</v-icon>
		</div>
		<div class="selector-container rounded-xl py-3 px-5 mb-2 d-flex flex-row justify-space-between"
			:class="typeShow === 7 ? 'selected white':''"
			@mouseover="typeShowChange(7)">
			<div>
				<h4 class="my-0">¡Únete al equipo!</h4>
				<p class="mb-0">Conviértete en vendedor Iduam ...</p>
			</div>

			<v-icon
				large
				color="grey lighten-1"
			>
				mdi-chevron-right
			</v-icon>
		</div>
  </v-container>
</template>

<script>

export default {
  name: "InfoSectionHero",
	props: {
		typeShow: {
			type: Number,
			required: true
		},
		typeShowChange: {
			type: Function,
			required: true
		}
	},
  components: {
  },
  data() {
    return {
    };
  },
  mounted() {
  },
};
</script>

<style scoped>
.selector-container{
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	border: 1px solid grey!important;
	cursor: pointer;
	background-color: rgb(248, 248, 248);
}
.selector-container.selected{
	border: 2px solid #064bac!important;
}
</style>