import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticStyle:{"background-color":"#0064F1"},attrs:{"fluid":""}},[_c(VContainer,[_c(VRow,{attrs:{"align":"center","no-gutters":""}},[_c(VCol,[_c('h3',{staticClass:"white--text mt-0"},[_vm._v("¡Sé vendedor ahora!")]),_c('p',{staticClass:"white--text"},[_vm._v("Inscríbete ahora y empieza a vender productos Iduam, te apoyaremos en todo el proceso")])]),_c(VCol,{attrs:{"md":"4"}},[_c(VBtn,{attrs:{"depressed":"","color":"white","block":"","href":"#contact"}},[_c(VIcon,{staticClass:"mr-3"},[_vm._v(" mdi-card-account-mail-outline ")]),_vm._v(" Conviértete en Vendedor ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }