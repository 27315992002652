<template>
  <v-container fluid id="contact"
		style="background: rgb(0,100,241);background: linear-gradient(0deg, rgba(0,100,241,1) 0%, rgba(0,100,241,1) 30%, rgba(238,245,255,1) 30%);">
		<v-container>
			<h2 class="text-center mb-10">Comienza a vender Iduam</h2>
			<v-row align="end">
				<v-col md="5" align-self="center" class="fill-height d-flex flex-column">
					<p>Ingresa tu información y te enviaremos:</p>
					<ul>
						<li>Tu código de descuento para tu pack inicial</li>
						<li>Toda la información necesaria para que puedas comenzar a ganar comisión con Iduam</li>
					</ul>
					<v-img
						max-height="140"
						contain
						:src="require('@/assets/images/logo-top.svg')"
						class="mb-0 mt-8"
					></v-img>
				</v-col>
				<v-col md="4">
					<v-card class="px-6 py-4" shaped hover>
						<v-card-text>
							<ContactForm />
						</v-card-text>
					</v-card>
				</v-col>
				<v-col md="3">
					<v-img
						max-height="200"
						contain
						:src="require('@/assets/images/tele.svg')"
						class="mb-0"
					></v-img>
				</v-col>
			</v-row>
		</v-container>
  </v-container>
</template>

<script>
import ContactForm from './ContactForm'

export default {
  name: "Contact",
  components: {
		ContactForm
  },
  data() {
    return {
    };
  },
  methods: {
	},
  mounted() {
  },
};
</script>

<style>
</style>