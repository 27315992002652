<template>
  <div>
    <Header/>
		<Hero/>
    <Info/>
    <Action/>
    <Know/>
    <Contact/>
    <Footer />
  </div>
</template>

<script>
import Header from "./Header.vue"
import Hero from "./Hero"
import Info from "./Info"
import Action from "./Action"
import Know from "./Know"
import Contact from "./Contact"
import Footer from "./Footer.vue"

export default {
  name: "Index",
  components: {
		Header,
		Hero,
		Info,
		Action,
		Know,
		Contact,
		Footer
  },
  data() {
    return {
      key: 0,
    };
  },
  methods: {},
  mounted() {
  },
};
</script>

<style>
</style>