<template>
  <v-container fluid class="pa-0 d-flex flex-row mb-3" style="overflow-x: scroll;">
		<div class="selector-container py-1 px-1 d-flex flex-row justify-center align-center text-center" style="min-width: 120px"
			:class="typeShow === 1 ? 'selected white':''"
			@mouseover="typeShowChange(1)">
				<p class="my-0">¿Qué es Iduam?</p>
		</div>
		<div class="selector-container py-1 px-1 d-flex flex-row justify-center align-center text-center" style="min-width: 120px"
			:class="typeShow === 2 ? 'selected white':''"
			@mouseover="typeShowChange(2)">
				<p class="my-0">¿Qué le ofrecemos?</p>
		</div>
		<div class="selector-container py-1 px-1 d-flex flex-row justify-center align-center text-center" style="min-width: 120px"
			:class="typeShow === 3 ? 'selected white':''"
			@mouseover="typeShowChange(3)">
				<p class="my-0">¿Cuáles son tus beneficios?</p>
		</div>
		<div class="selector-container py-1 px-1 d-flex flex-row justify-center align-center text-center" style="min-width: 120px"
			:class="typeShow === 4 ? 'selected white':''"
			@mouseover="typeShowChange(4)">
				<p class="my-0">¿Cómo te apoyamos?</p>
		</div>
		<div class="selector-container py-1 px-1 d-flex flex-row justify-center align-center text-center" style="min-width: 120px"
			:class="typeShow === 5 ? 'selected white':''"
			@mouseover="typeShowChange(5)">
				<p class="my-0">¿Qué es el Jackpot?</p>
		</div>
		<div class="selector-container py-1 px-1 d-flex flex-row justify-center align-center text-center" style="min-width: 120px"
			:class="typeShow === 6 ? 'selected white':''"
			@mouseover="typeShowChange(6)">
				<p class="my-0">¿Cuáles son las condiciones?</p>
		</div>
		<div class="selector-container py-1 px-1 d-flex flex-row justify-center align-center text-center" style="min-width: 120px"
			:class="typeShow === 7 ? 'selected white':''"
			@mouseover="typeShowChange(7)">
				<p class="my-0">¡Únete al equipo!</p>
		</div>
  </v-container>
</template>

<script>

export default {
  name: "InfoSelectorHeroMobile",
	props: {
		typeShow: {
			type: Number,
			required: true
		},
		typeShowChange: {
			type: Function,
			required: true
		}
	},
  components: {
  },
  data() {
    return {
    };
  },
  mounted() {
  },
};
</script>

<style scoped>
.selector-container{
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	border: 1px solid grey!important;
	cursor: pointer;
	background-color: rgb(248, 248, 248);
}
.selector-container.selected{
	border: 2px solid #064bac!important;
}
</style>