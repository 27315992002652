<template>
	<v-dialog
			v-model="dialog"
			persistent
			max-width="900px"
			content-class="custom-dialog"
		>
		<v-card>
			<v-btn
				icon
				color="#707070"
				@click="closeModal"
				style="position: absolute; top: 20px; right: 20px;"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-card-text class="py-4">
				<h4>Beneficios Aplicación Móvil Iduam</h4>
				<v-container>
					<v-row align="center">
						<v-col cols="12" md="9" style="color: #0064F1" class="text-center justify-center">
							<v-expansion-panels :value="0">
								<v-expansion-panel
									v-for="(item,i) in texts"
									:key="i"
								>
									<v-expansion-panel-header color="#0064F1">
										<h5 class="body-1 font-weight-bold white--text my-0">{{item.title}}</h5>
										<template v-slot:actions>
											<v-icon color="white">
												mdi-chevron-down
											</v-icon>
										</template>
									</v-expansion-panel-header>
									<v-expansion-panel-content class="pa-3" v-html="item.text">
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-col>
						<v-col cols="12" md="3" style="color: #707070" class="text-center justify-center">
							<v-img
								:src="require('@/assets/images/logo-top.svg')"
								width="200"
								max-height="300"
								contain
								class="logo mx-auto mb-0"
							></v-img>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	
	export default {
		name: 'ModalBenefits',
	props: {
		dialog: {
			type: Boolean,
			default: false
		},
		closeModal: {
			type: Function,
			required: true
		}
	},
		data: () => ({
			texts: [
				{
					title: 'Botón de Emergencia',
					text: '<p class="mb-0 text-justify">La Aplicación Móvil <b>“Iduam”</b> cuenta en su pantalla principal con un Botón de Emergencia que al presionarlo, activará una alerta la cual será recibida por cada una de las personas que componen su Red de Seguridad Personal, los cuales podrán comunicarse con el usuario a través de video conferencia.</p>'
				},
				{
					title: 'Red de Seguridad Personal',
					text: '<p class="mb-0 text-justify">Usted o el usuario que descargue la Aplicación Móvil <b>“Iduam”</b> a su smartphone, podrá generar su propia Red de Emergencia Personal de Salud de 5 personas como máximo, la cual ante una alerta que el usuario principal genere, podrán comunicarse con el referido usuario o usted por video conferencia.</p>'
				},
				{
					title: 'Video Conferencia entre Usuarios que componen la Red',
					text: '<p class="mb-0 text-justify">Usted podrá comunicarse por video conferencia con cada uno de los usuarios que componen su Red de Seguridad Personal, ya sea con uno o con todos a la vez y ellos entre si también podrán comunicarse.</p>'
				},
				{
					title: 'Acceso a su Big Data o Panel WEB de Mediciones',
					text: '<p class="mb-0 text-justify">Todas las mediciones que Usted se haya realizado con nuestra Aplicación Móvil <b>“Iduam”</b> quedarán registradas en nuestros Servidores y Usted podrá acceder a sus mediciones históricas, en la WEB principal del Sistema <b>“Iduam”</b> en el Menú en la alternativa “Iniciar Sesión” incorporando las mismas claves que Usted utiliza en su Aplicación Móvil.</p>'
				},
				{
					title: 'Monitoreo Periódico',
					text: '<p class="mb-0 text-justify">Con la Aplicación Móvil <b>“Iduam”</b> usted además de poder monitorear sus parámetros de salud en forma manual, también podrá automatizar sus monitoreos, tanto de sus parámetros de: Presión Arterial, Ritmo Cardiaco y Saturación de Oxigeno, bajo un concepto tipo Holter. Estas mediciones quedarán registradas en nuestro Big Data y estará a tu disposición y la de su médico a través del Panel WEB de Mediciones al cual Usted podrá también acceder.</p>'
				},
				{
					title: 'Monitorización Electrocardiográfica',
					text: '<p class="mb-0 text-justify">Usted podrá realizarse una Monitorización Electrocardiográfica desde la propia Aplicación Móvil <b>“Iduam”</b>, la cual sin mayores costos para Usted podrá ser interpretada en forma preventiva por un algoritmo de inteligencia artificial.</p>'
				}
			]
		}),
		mounted () {
		},
		methods: {
		}
	}
</script>
<style scoped>
.logo{
	background-color: #0064F1;
	border-radius: 100%;
	aspect-ratio: 1;
}
</style>