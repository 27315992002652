import {endpoints} from './endpoints'
import request from '../request'

export function addSellerApplication (data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.addSellerApplication,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error.response)
			})
	})
}

export function getSellerApplicationByID (data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.getSellerApplicationByID,
			method: 'get',
			params: data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error.response)
			})
	})
}

export function getSellerByCode (data) {
	return new Promise((resolve) => {
		console.log(data)
		request({
			url: endpoints.getSellerByCode,
			method: 'get',
			params: data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error.response)
			})
	})
}

export function askForSellerContact (data) {
	return new Promise((resolve) => {
		console.log(data)
		request({
			url: endpoints.askForSellerContact,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error.response)
			})
	})
}