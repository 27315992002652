<template>
  <v-container fluid style="background-color: #007BED;overflow-y: scroll;" class="fill-height align-start">
    <v-snackbar
      v-model="showMessages"
      vertical
      top
      right
    >
      <p class="mb-0">Hay errores en el formulario:</p>
      <p
        v-for="(msg, index) in messages"
        :key="index"
        class="mb-0 d-block"
        >{{(index + 1) + ': ' + msg}}</p>
    </v-snackbar>
    <v-row class="text-center">
      <v-col cols="12">
        <div class="mx-auto" style="max-width: 500px;">
          <v-img
            :src="require('@/assets/images/logo-top.svg')"
            class="my-3"
            contain
            height="200"
          />
          <div v-if="registerOK">
            <v-img src="@/assets/images/shield.svg" max-height="200px" contain></v-img>
            <p class="white--text my-5 display-1">Registro Completo</p>
          </div>
          <v-form
            v-else
            ref="form"
            class="custom-form-registro"
            v-model="valid"
          >
            <div class="text-center">
              <p class="white--text mb-0">Registra tu cuenta Iduam</p>
              <p class="white--text mb-0">Ingresa los siguientes datos y podrás formar parte del equipo de vendedores Iduam</p>
              <v-text-field
                v-model="userInfo.name"
                :rules="required"
                label="Nombre"
                required
                dark
              ></v-text-field>
              <v-text-field
                v-model="userInfo.rut"
                :rules="rut"
                label="RUT"
                required
                @change="(rut) => {formatRut(rut)}"
                dark
              ></v-text-field>
              <v-text-field
                v-model="userInfo.email"
                :rules="isEmailValid"
                label="Email"
                required
                @change="(email) => {isEmail(email)}"
                dark
              ></v-text-field>
              <v-select
                v-model="userInfo.pais"
                :items="paises"
                :rules="required"
                item-text="name"
                item-value="id"
                hint="Se muestran sólo los paises en los que Iduam está disponible"
                persistent-hint
                label="Paises"
                dark
                @change="getRegion"
              ></v-select>
              <v-select
                v-model="userInfo.region"
                :items="regiones"
                :rules="required"
                item-text="region"
                item-value="id"
                hint="Se muestran sólo las regiones en las que Iduam está disponible"
                persistent-hint
                label="Región"
                dark
                @change="getComunasActivas"
              ></v-select>
              <v-select
                v-if="comunas.length > 0"
                v-model="userInfo.comuna"
                :items="comunas"
                :rules="required"
                item-text="comuna"
                item-value="id"
                hint="Se muestran sólo las comunas en las que SOSclick está disponible"
                persistent-hint
                label="Comuna"
                dark
              ></v-select>
              <v-text-field
                v-model="userInfo.direccion"
                :rules="required"
                label="Dirección"
                required
                dark
              ></v-text-field>
              <v-text-field
                v-model="userInfo.telefono"
                :rules="requiredPhone"
                label="Teléfono"
                :counter="11"
                maxlength="11"
                required
                dark
              ></v-text-field>
              <v-select
                v-model="userInfo.sexo"
                :items="items"
                :rules="required"
                label="Género"
                dark
              ></v-select>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="userInfo.birthday"
                    :rules="required"
                    label="Fecha Nacimiento"
                    readonly
                    v-bind="attrs"
                    prepend-inner-icon="mdi-calendar"
                    v-on="on"
                    dark
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  locale="es-ES"
                  dark
                  v-model="userInfo.birthday"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="(date) => $refs.menu.save(date)"
                ></v-date-picker>
              </v-menu>
              <v-text-field
                v-model="userInfo.password"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="required"
                :type="showPass ? 'text' : 'password'"
                name="input-10-1"
                label="Contraseña"
                hint="Mínimo 8 caracteres"
                dark
                @click:append="showPass = !showPass"
              ></v-text-field>
              <v-btn class="mt-4" depressed dark block @click="() => registro()">Registrar</v-btn>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { validate, format, clean } from 'rut.js'
  import {paisesActivos, regionesActivas, comunasActivas, login, webSignUp} from '../../helpers/api/user'
	import {getSellerApplicationByID} from '../../helpers/api/sellers'

  export default {
    name: 'Register',
    data: () => ({
			redirect: null,
      menu: false,
      showMessages: false,
      messages: null,
      showPass: false,
      registerOK: false,
      paises: [],
      regiones: [],
      comunas: [],
      userInfo: {
        name: '',
        email: '',
        direccion: '',
        telefono: '',
        rut: '',
        sexo: 2,
        birthday: new Date().toISOString().substr(0, 10),
        password: '',
        comuna: null
      },
      valid: false,
      required: [
        v => !!v || 'Este campo es obligatorio'
      ],
      rut: [
        v => (!!v || 'Este campo es obligatorio') && (validate(v) || 'Rut no válido')
      ],
      requiredPhone: [
        v => !!v || 'Este campo es obligatorio',
        v => ( !!v && /^\d+$/.test(v) ) || 'Ingrese sólo números'
      ],
      isEmailValid: [
        //eslint-disable-next-line
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Formato incorrecto'
      ],
      items: [{text: 'Femenino', value: 2}, {text: 'Masculino', value: 1}]
    }),
    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },
    mounted(){
      this.getPaises()
			this.getPreSellerInfo()
			this.redirect = this.$router.currentRoute.query.redirect
    },
    methods: {
			async getPreSellerInfo(){
				const data = {
					seller_id: this.$router.currentRoute.query.seller
				}
				const seller = await getSellerApplicationByID(data)
				if(seller.code == 200){
					this.userInfo.name = seller.seller_applicant.name
					this.userInfo.email = seller.seller_applicant.email
					this.formatRut(seller.seller_applicant.rut)
				}
			},
      formatRut(rut) {
        this.userInfo.rut = format(rut)
      },
      isEmail(email){
        //eslint-disable-next-line
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
      },
      async getPaises(){
        const paises = await paisesActivos()
        this.paises = paises.paises
      },
      async getRegion(pais_id){
        const regiones = await regionesActivas({pais_id})
        this.regiones = regiones.regiones
      },
      async getComunasActivas(region_id){
        const comunas = await comunasActivas({region_id})
        this.comunas = comunas.comunas
      },
      async registro(){
        if(this.$refs.form.validate()){
          const self = this
          let data = this.userInfo
					data.rut = clean(data.rut)
					data.seller_applicant_id = this.$router.currentRoute.query.seller
					
          webSignUp(data)
            .then(async function () {
              self.registerOK = true
							if(self.redirect){
								window.location.href = '/' + self.redirect
							}
            })
            .catch(function (error) {
              self.messages = Object.values(error)
              self.showMessages = true
              console.log(self.messages)
            });
        }
      },
      loginUser () {
        const self = this
        login(this.userInfo.email, this.userInfo.password)
          .then(function () {
            self.$router.push({name: 'Home'})
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    }
  }
</script>
<style type="text/css" media="screen">
  .v-application .custom-form-registro .error--text {
    color: white !important;
    caret-color: #f2f2f2 !important;
  }
</style>