import {endpoints} from './endpoints'
import request from '../request'

export function listadoCiudadesDestino (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.listadoCiudadesDestino,
			method: 'get',
			params: data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createPurchaseOrder (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createPurchaseOrder,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCartData (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCartData,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}