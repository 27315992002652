<template>
	<v-dialog
			v-model="dialog"
			persistent
			max-width="900px"
			content-class="custom-dialog"
		>
		<v-card>
			<v-btn
				icon
				color="#707070"
				@click="closeModal"
				style="position: absolute; top: 20px; right: 20px;"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-card-text class="py-4">
				<h4>Beneficios de Convertirte en Vendedor</h4>
				<v-container>
					<v-row align="center">
						<v-col cols="12" md="9" style="color: #0064F1" class="text-center justify-center">
							<v-expansion-panels :value="0">
								<v-expansion-panel
									v-for="(item,i) in texts"
									:key="i"
								>
									<v-expansion-panel-header color="#0064F1">
										<h5 class="body-1 font-weight-bold white--text my-0">{{item.title}}</h5>
										<template v-slot:actions>
											<v-icon color="white">
												mdi-chevron-down
											</v-icon>
										</template>
									</v-expansion-panel-header>
									<v-expansion-panel-content class="pa-3 text-justify" v-html="item.text">
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-col>
						<v-col cols="12" md="3" style="color: #707070" class="text-center justify-center">
							<v-img
								:src="require('@/assets/images/logo-top.svg')"
								width="200"
								max-height="300"
								contain
								class="logo mx-auto mb-0"
							></v-img>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	
	export default {
		name: 'ModalBenefits',
	props: {
		dialog: {
			type: Boolean,
			default: false
		},
		closeModal: {
			type: Function,
			required: true
		}
	},
		data: () => ({
			texts: [
				{
					title: '¿Qué es Iduam?',
					text: 'Es un asistente que monitorea parámetros de Salud tales como: Presión Arterial, Ritmo Cardíaco, Saturación de Oxígeno, Temperatura y permite realizar una Monitorización Electrocardiográfica la cual es interpretada a modo preventivo por un algoritmo de inteligencia artificial en forma constante y proactiva. Además, Usted podrá ingresar a un Portal WEB en donde accedera a la historia de sus mediciones utilizando sus propias claves personales. Este producto tiene un valor anual de $149.990 iva incluido para nuestros clientes directos y consta de una smartband o pulsera inteligente la cual se conecta a una Aplicación Móvil de nombre Iduam. Esta Aplicación Móvil le permitirá en forma adicional a las mediciones de salud que usted puede monitorear, generar su propia Red de Seguridad, invitando por ejemplo a familiares, amigos, etc, que le asistirán por video conferencia en caso de que usted tenga alguna emergencia y haya activado el Botón de Emergencia o Alerta del Aplicativo Móvil.'
				},
				{
					title: '¿Qué le ofrecemos?',
					text: 'Unirse a un grupo enfocado en la venta de nuestro exclusivo producto Iduam, para lo cual le apoyaremos con videos, campañas telefónicas y comunicaciones cuando usted necesite, con el objeto de ayudarlo a resolver sus dudas y las de sus Clientes.'
				},
				{
					title: '¿Cuáles son tus beneficios?',
					text: 'Registrando sus datos en nuestro formulario, podrá obtener un descuento de $70.000.- del valor del Sistema Iduam, el cual incluye la smartband o pulsera inteligente y el uso de la Aplicación Móvil indefinidamente mientras Usted venda nuestro producto. Además le ofrecemos ganar por cada venta que realice una comisión de $10.000. Estas comisiones le serán pagadas una vez por semana a la cuenta que Usted nos haya indicado en su registro. El envío de la pulsera se hará a través de la empresa Starken y correrá por cuenta del comprador.'
				},
				{
					title: '¿Cómo te apoyamos?',
					text: 'Nuestra empresa hará campañas directas para ofrecer IDUAM, le entregaremos listas de clientes interesados solo para que usted haga el cierre del negocio, además estaremos atentos a sus consultas y le daremos apoyo con sus clientes, a partir de la biblioteca de videos tutoriales que hemos subido a Youtube.'
				},
				{
					title: '¿Qué es el Jackpot?',
					text: 'Todas la ventas realizadas por vendedores, generarán un pozo que se irá acumulando durante el mes y este será repartido entre los tres vendedores que hayan realizado mas ventas en la proporción de 50% al primer lugar, 30% al segundo y 20% al tercer lugar.'
				},
				{
					title: '¿Cuáles son las condiciones?',
					text: 'Para ser parte de nuestro grupo de Vendedores, primero Usted deberá registrarse en el formulario adjunto, luego recibirá un correo indicándole el código de descuento para que Usted compre su producto Iduam a un precio menor del que paga el Cliente final. El Código de vendedor que le entregaremos le permitirá obtener   la comisión por la venta realizada. Usted para vender nuestro producto deberá comprar el Sistema Iduam con el descuento anteriormente mencionado, así se activará su código como vendedor del Sistema.'
				}
			]
		}),
		mounted () {
		},
		methods: {
		}
	}
</script>
<style scoped>
.logo{
	background-color: #0064F1;
	border-radius: 100%;
	aspect-ratio: 1;
}
</style>