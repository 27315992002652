import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticStyle:{"background":"linear-gradient(0deg, rgba(0,100,241,1) 0%, rgba(0,100,241,1) 30%, rgba(238,245,255,1) 30%)"},attrs:{"fluid":"","id":"contact"}},[_c(VContainer,[_c('h2',{staticClass:"text-center mb-10"},[_vm._v("Comienza a vender Iduam")]),_c(VRow,{attrs:{"align":"end"}},[_c(VCol,{staticClass:"fill-height d-flex flex-column",attrs:{"md":"5","align-self":"center"}},[_c('p',[_vm._v("Ingresa tu información y te enviaremos:")]),_c('ul',[_c('li',[_vm._v("Tu código de descuento para tu pack inicial")]),_c('li',[_vm._v("Toda la información necesaria para que puedas comenzar a ganar comisión con Iduam")])]),_c(VImg,{staticClass:"mb-0 mt-8",attrs:{"max-height":"140","contain":"","src":require('@/assets/images/logo-top.svg')}})],1),_c(VCol,{attrs:{"md":"4"}},[_c(VCard,{staticClass:"px-6 py-4",attrs:{"shaped":"","hover":""}},[_c(VCardText,[_c('ContactForm')],1)],1)],1),_c(VCol,{attrs:{"md":"3"}},[_c(VImg,{staticClass:"mb-0",attrs:{"max-height":"200","contain":"","src":require('@/assets/images/tele.svg')}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }