<template>
  <v-container fluid class="sellers-hero-section fill-height" style="max-height: 80vh; position: relative;">
		<v-container class="containerhtml-regular fill-height">
			<v-row
				align="center"
				>
				<v-col md="7" class="fill-height" order="2" order-md="1">
					<h2 class="grey--text mb-0">Únete al equipo de ventas</h2>
					<h2 class="display-3 font-weight-bold mt-0 mb-4" style="color: #0064F1;">IDUAM</h2>
					<p class="grey--text text--darken-2 body-1 mb-0" style="max-width: 400px;">Sé parte del equipo de ventas Iduam y gana <b>$10.000</b> de comisión por cada venta que generes</p>
					<p class="grey--text text--darken-2 caption">*Recuerda que para habilitarte como vendedor debes adquirir el kit básico (con un importante descuento de $70.000)</p>
					<div class="d-flex" :class="$vuetify.breakpoint.mobile ? 'flex-column':''">
						<v-btn
							color="#0064F1"
							@click="modalBenefitsSellerStatus = true"
							class="my-2"
							:class="!$vuetify.breakpoint.mobile ? 'mr-4':''"
							dark
							:loading="sendingForm"
						>
							<v-icon class="mr-3">
								mdi-account-cash-outline
							</v-icon>
							¿En que me beneficia?
							<v-icon class="my-3">
								mdi-chevron-right
							</v-icon>
						</v-btn>
						<v-btn
							color="#0064F1"
							@click="modalBenefitsStatus = true"
							class="my-2"
							dark
							:loading="sendingForm"
						>
							Beneficios de la app
							<v-icon class="my-3">
								mdi-chevron-right
							</v-icon>
						</v-btn>
					</div>
				</v-col>
				<v-col md="5" class="fill-height" order="1" order-md="2">
					<v-card rounded="xl">
						<v-img
							:src="require('@/assets/images/team.png')"
							class="rounded-t-xl"
							position="top"
							eager
							:max-height="!$vuetify.breakpoint.mobile ? 220:100"
						>
							<v-card-title class="white--text fill-height d-flex flex-column justify-end align-start">
								<h2 class="my-4 headline font-weight-black">¡Únete al Equipo!</h2>
							</v-card-title>
						</v-img>
						<v-card-text>
							<p class="subtitle-2 text-center">Conviértete en vendedor Iduam y gana comisión por todas las ventas que realices</p>
							<ContactForm />
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
		<ModalBenefits :dialog="modalBenefitsStatus" :closeModal="() => modalBenefitsStatus = false"/>
		<ModalBenefitsSeller :dialog="modalBenefitsSellerStatus" :closeModal="() => modalBenefitsSellerStatus = false"/>
  </v-container>
</template>

<script>
import InfoSectionHero from './InfoSectionHero'
import InfoSelectorHero from './InfoSelectorHero'
import InfoSelectorHeroMobile from './InfoSelectorHeroMobile'
import ContactForm from './ContactForm'
import ModalBenefits from './ModalBenefits.vue'
import ModalBenefitsSeller from './ModalBenefitsSeller.vue'

export default {
  name: "Hero",
  components: {
		ContactForm,
		ModalBenefits,
		ModalBenefitsSeller,
		InfoSectionHero,
		InfoSelectorHero,
		InfoSelectorHeroMobile
  },
  data() {
    return {
			typeShow: 8,
			progress: 0,
			timer: null,
			timerProgress: null,
			modalBenefitsStatus: false,
			modalBenefitsSellerStatus: false
    };
  },
  mounted() {
		if(this.$vuetify.breakpoint.mobile){
			this.typeShow = 7
		}
		else{
		this.startTimer()
		}
  },
  methods: {
		typeShowChange(type){
			this.typeShow = type
			if(this.timer){
				clearInterval(this.timer)
				this.timer = null
			}
			if(this.timerProgress){
				clearInterval(this.timerProgress)
				this.timerProgress = null
			}
		},
		startTimer(){
			this.timer = setInterval(() => {
				this.progress = 0
				if(this.typeShow === 8){
					this.typeShow = 1
					return
				}
				this.typeShow++
			}, 5000);
			this.timerProgress = setInterval(() => {
				this.progress = this.progress + 4
			}, 200);
		}
	},
};
</script>

<style scoped>
	.sellers-hero-section{
		background-image: url('~@/assets/images/back1.jpg');
		background-color: #0064F1;
		background-position: center;
    background-size: cover;
		background-attachment: fixed;
	}
	.sellers-hero-section .containerhtml-regular{
		overflow-y: scroll;
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
	.sellers-hero-section .containerhtml-regular::-webkit-scrollbar {
		display: none;
	}
	.logo{
		background-color: #0064F1;
		/* border-radius: 100%;
		aspect-ratio: 1; */
	}
</style>