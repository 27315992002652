<template>
  <v-container fluid class="pa-0">
		<div
				v-if="typeShow != 7 && typeShow != 8"
				class="rounded-xl mb-2 py-2"
				style="background: #0064F1">
			<v-img
				contain
				max-height="100"
				:src="require('@/assets/images/logo-top.svg')"
				class="mb-0 rounded-xl"
			></v-img>
		</div>
		<v-card rounded="xl">
			<v-card-text class="text-justify" v-if="typeShow === 1">Es un asistente que monitorea parámetros de Salud tales como: Presión Arterial, Ritmo Cardíaco, Saturación de Oxígeno, Temperatura y permite realizar una Monitorización Electrocardiográfica la cual es interpretada a modo preventivo por un algoritmo de inteligencia artificial en forma constante y proactiva. Además, Usted podrá ingresar a un Portal WEB en donde accedera a la historia de sus mediciones utilizando sus propias claves personales. Este producto tiene un valor anual de $149.990 iva incluido para nuestros clientes directos y consta de una smartband o pulsera inteligente la cual se conecta a una Aplicación Móvil de nombre Iduam. Esta Aplicación Móvil le permitirá en forma adicional a las mediciones de salud que usted puede monitorear, generar su propia Red de Seguridad, invitando por ejemplo a familiares, amigos, etc, que le asistirán por video conferencia en caso de que usted tenga alguna emergencia y haya activado el Botón de Emergencia o Alerta del Aplicativo Móvil.</v-card-text>
			<v-card-text class="text-justify" v-if="typeShow === 2">Unirse a un grupo enfocado en la venta de nuestro exclusivo producto Iduam, para lo cual le apoyaremos con videos, campañas telefónicas y comunicaciones cuando usted necesite, con el objeto de ayudarlo a resolver sus dudas y las de sus Clientes.</v-card-text>
			<v-card-text class="text-justify" v-if="typeShow === 3">Registrando sus datos en nuestro formulario, podrá obtener un descuento de $80.000.- del valor del Sistema Iduam, el cual incluye la smartband o pulsera inteligente y el uso de la Aplicación Móvil indefinidamente mientras Usted venda nuestro producto. Además le ofrecemos ganar por cada venta que realice una comisión de $30.000. Estas comisiones le serán pagadas una vez por semana a la cuenta que Usted nos haya indicado en su registro. El envío de la pulsera se hará a través de la empresa Starken y correrá por cuenta del comprador.</v-card-text>
			<v-card-text class="text-justify" v-if="typeShow === 4">Cada cliente suyo que ingrese su código de vendedor, al comprar tendrá un 10% de descuento en su compra, además estaremos atentos a sus consultas y le daremos apoyo con sus clientes, a partir de la biblioteca de videos tutoriales que hemos subido a Youtube.</v-card-text>
			<v-card-text class="text-justify" v-if="typeShow === 5">Todas la ventas realizadas por vendedores, generarán un pozo que se irá acumulando durante el mes y este será repartido entre los tres vendedores que hayan realizado mas ventas en la proporción de 50% al primer lugar, 30% al segundo y 20% al tercer lugar.</v-card-text>
			<v-card-text class="text-justify" v-if="typeShow === 6">Para ser parte de nuestro grupo de Vendedores, primero Usted deberá registrarse en el formulario adjunto, luego recibirá un correo indicandote el código de descuento para que Usted compre su producto Iduam a un precio menor del que paga el Cliente final. El Código de vendedor que le entregaremos le permitirá que sus clientes obtengan el 10% de descuento en sus compra. Usted para vender nuestro producto deberá comprar el Sistema Iduam con el descuento anteriormente mencionado, así se activará su código como vendedor del Sistema.</v-card-text>
			
			<div v-show="typeShow === 7">
				<v-img
					:src="require('@/assets/images/team.png')"
					class="rounded-t-xl"
					position="top"
					eager
					:max-height="!$vuetify.breakpoint.mobile ? 220:100"
				>
					<v-card-title class="white--text fill-height d-flex flex-column justify-end align-start">
						<h2 class="my-4 headline font-weight-black">¡Únete al Equipo!</h2>
					</v-card-title>
				</v-img>

				<v-card-text>
					<p class="subtitle-2 text-center">Conviértete en vendedor Iduam y gana comisión por todas las ventas que realices</p>

					<ContactForm />
				</v-card-text>
			</div>

			<v-img
				v-show="typeShow === 8"
				contain
				eager
				max-height="550"
				:src="require('@/assets/images/iduamapp.png')"
				class="mb-0 rounded-xl"
			></v-img>
		</v-card>

		<div
			v-if="typeShow === 1"
			class="selector-container rounded-xl py-3 px-5 mt-2 mb-0 d-flex flex-row justify-space-between"
			@click="() => modalBenefitsStatus = true">
			<div>
				<h4 class="my-0">Beneficios de la App</h4>
				<p class="mb-0">Conoce todos los beneficios de Iduam</p>
			</div>

			<v-icon
				large
				color="grey lighten-1"
			>
				mdi-chevron-right
			</v-icon>
		</div>
		<ModalBenefits :dialog="modalBenefitsStatus" :closeModal="() => modalBenefitsStatus = false"/>
  </v-container>
</template>

<script>
import ContactForm from './ContactForm'
import ModalBenefits from './ModalBenefits.vue'

export default {
  name: "InfoSectionHero",
	props: {
		typeShow: {
			type: Number,
			required: true
		}
	},
  components: {
		ContactForm,
		ModalBenefits
  },
  data() {
    return {
			modalBenefitsStatus: false
    };
  },
  methods: {},
  mounted() {
		setTimeout(() => {
			this.modalBenefitsStatus = true
		}, 1500);
  },
};
</script>

<style scoped>
.selector-container{
	border: 1px solid grey!important;
	cursor: pointer;
	background-color: #0064F1;
	color: white;
}
.selector-container:hover{
	border: 2px solid #064bac!important;
}
</style>