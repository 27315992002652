import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px","content-class":"custom-dialog"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VBtn,{staticStyle:{"position":"absolute","top":"20px","right":"20px"},attrs:{"icon":"","color":"#707070"},on:{"click":_vm.closeModal}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"text-center justify-center",staticStyle:{"color":"#707070"},attrs:{"cols":"12"}},[_c(VImg,{staticClass:"mx-auto mb-6",attrs:{"src":require('@/assets/images/success.svg'),"width":"250","max-height":"300","contain":""}}),_c('h3',{staticClass:"headline"},[_vm._v("¡Genial, hemos recibido tus datos correctamente!")]),_c('p',{staticClass:"mb-0"},[_vm._v("Gracias por registrarte como vendedor.")]),_c('p',{staticClass:"font-weight-bold my-4"},[_vm._v("Te recordamos que para concluir el proceso de registrarte como vendedor tendrás que comprar el Kit de IDUAM con un descuento especial para ti de $80.000 sobre el precio de venta, por lo que solo tendrás que pagar $69.990")]),_c(VDivider),_c('p',{staticClass:"font-weight-bold mt-4 mb-0"},[_vm._v("Recibirás un email en el correo electrónico que ingresaste.")]),_c('p',{staticClass:"caption mb-0"},[_vm._v("Por favor revisa tu email en unos segundos, verifica también tu carpeta spam.")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }