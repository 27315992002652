import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"id":"info"}},[_c(VRow,{staticClass:"my-5",attrs:{"align":"center","no-gutters":""}},[_c(VCol,[_c('div',{staticClass:"d-flex flex-column justify-center align-center flex-1 py-4 px-5"},[_c(VImg,{staticClass:"mb-5",attrs:{"max-height":"150","max-width":"150","src":require('@/assets/images/money.png')}}),_c('p',{staticClass:"mt-4 text-center"},[_vm._v("Obtén ganancias por promover y vender nuestros productos")])],1)]),_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,[_c('div',{staticClass:"d-flex flex-column justify-center align-center flex-1 py-4 px-5"},[_c(VImg,{staticClass:"mb-5",attrs:{"max-height":"150","max-width":"150","src":require('@/assets/images/cupon.png')}}),_c('p',{staticClass:"mt-4 text-center"},[_vm._v("Adquiere una muestra de nuestros productos con un descuento de $80.000 en tu primera compra")])],1)]),_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,[_c('div',{staticClass:"d-flex flex-column justify-center align-center flex-1 py-4 px-5"},[_c(VImg,{staticClass:"mb-5",attrs:{"max-height":"150","max-width":"150","src":require('@/assets/images/wallet.png')}}),_c('p',{staticClass:"mt-4 text-center"},[_vm._v("Gana comisión por todas las ventas que se cierren con tu código de referido")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }