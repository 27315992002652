import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{staticClass:"my-5",attrs:{"align":"start"}},[_c(VCol,[_c('h2',{staticClass:"text-center mb-4"},[_vm._v("¿Qué es Iduam?")]),_c('p',{staticClass:"text-justify mb-6"},[_vm._v("Es un asistente que monitorea parámetros de salud como la Presión Arterial, Ritmo Cardíaco, Saturación de Oxígeno en forma constante y proactiva, incluso con la posibilidad de generar una Monitorización Electrocardiográfica con la interpretación de un algoritmo de inteligencia artificial (IA) en tiempo real.")]),_c('div',{staticClass:"mx-auto text-center",staticStyle:{"max-width":"500px"}},[_c('iframe',{attrs:{"width":"100%","height":"315","src":"https://www.youtube.com/embed/RW_EclTXHxk","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]),_c(VBtn,{staticClass:"mt-6",attrs:{"depressed":"","color":"#0064F1","dark":"","block":"","href":"/","target":"_blank"}},[_c(VIcon,{staticClass:"mr-3"},[_vm._v(" mdi-card-account-mail-outline ")]),_vm._v(" Conoce más sobre Iduam ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }