<template>
	<v-form
			ref="form"
			v-model="valid"
			lazy-validation
			class="d-flex flex-column flex-grow-1"
	>
		<v-text-field
				v-model="name"
				:rules="requiredRules"
				label="Nombre"
				required
				class="custom-placeholer-color"
		></v-text-field>
		<!-- <v-text-field
				v-model="rut"
				:rules="rutRules"
				validate-on-blur
				label="Rut"
				required
				class="custom-placeholer-color"
				@blur="formatRut"
		></v-text-field> -->
		<v-text-field
				v-model="email"
				:rules="emailRules"
				label="Email"
				required
				class="custom-placeholer-color"
		></v-text-field>
		<v-btn
			color="#0064F1"
			@click="sendForm"
			class="mt-4"
			dark
			:loading="sendingForm"
		>
			Enviar Datos
		</v-btn>
    <v-snackbar
        v-model="showMessage"
        vertical
        bottom
        center
        :color="messageColor"
    >
      <div v-html="message"></div>
    </v-snackbar>
		<ModalConfirmation :dialog="modalConfirmationStatus" :closeModal="() => modalConfirmationStatus = false"/>
	</v-form>
</template>

<script>
import { validate, format, clean } from 'rut.js'
import {addSellerApplication} from '@/helpers/api/sellers'
import ModalConfirmation from './ModalConfirmation'

export default {
  name: "ContactForm",
  components: {
		ModalConfirmation
  },
  data() {
    return {
    	valid: false,
			name: '',
			rut: null,
			email: '',
			showMessage: false,
			messageColor: "error",
			message: "",
			requiredRules: [(v) => !!v || "Este campo es requerido"],
			emailRules: [
				(v) => !!v || "Email es obligatorio",
				(v) => /.+@.+\..+/.test(v) || "Email debe ser válido",
			],
			rutRules: [(v) => validate(v) || "Por favor revisa el rut ingresado"],
			modalConfirmationStatus: false,
			sendingForm: false
    };
  },
  methods: {
		formatRut(event){
			const rutValue = event.target.value
			if(rutValue && validate(rutValue)){
				this.rut = format(rutValue)
			}
			else{
				this.rut = clean(rutValue)
			}
		},
		async sendForm(){
			this.showMessage = false
			this.messageColor = "error"
			this.message = ""
			this.sendingForm = true
			if( this.$refs.form.validate() ){
				const data = {
					name: this.name,
					rut: clean(this.rut),
					email: this.email
				}
				const resp = await addSellerApplication(data)
				this.sendingForm = false
				if(resp.code == 200){
					this.modalConfirmationStatus = true
					this.name = ''
					this.rut = null
					this.email = ''
					this.valid = false
					this.$refs.form.resetValidation()
				}
				else{
					this.message += '<ul class="mb-0">'
					resp.data.forEach(element => {
						this.message += "<li>" + element + "</li>"
					});
					this.message += '</ul>'
					this.messageColor = "warning"
					this.showMessage = true
				}
				console.log(resp)
			}
		}
	},
};
</script>

<style>
</style>